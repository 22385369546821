import React, { useEffect } from 'react';
import i18n from 'utils/i18n';
import { PageProvider } from 'providers/page';
import Link from 'next/link';

import css from './styles.module.scss';
import Head from 'next/head';

function NotFound (props) {
  const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('pages.404');

  useEffect(() => {
    console.log('404 has loaded');
  }, []);

  return (
    <div className={css["page__404-container"]}>
      <Head>
        {/*
          // TODO: Add title, description and meta data for your page
          */}
      </Head>

      <h2>{t('404-title')}</h2>
      <div>
        {t('404-content')}
      </div>
      <Link href="/">
        <a>
          {gt('back-to-home')}
        </a>
      </Link>
    </div>
  );
}

export default function NotFoundPage (props) {
  const { children, ...other } = props;
  return <PageProvider><NotFound {...other} />{children}</PageProvider>;
}

export async function getStaticProps(context) {
  const { locale } = context;

  return {
    props: {
      messages: await i18n.getMessages(locale),
    }
  };
}
